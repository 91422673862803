const logResponse = (response, endpoint) => {
    console.log(`API Response to ${endpoint}`);
    console.log(response);
}

const doFetch = async (endpoint, method, rawBody = null, formData = false, logout) => {
    const baseURL = `https://api.myguestlists.com`;
    let isRefreshing = false; // Flag para controlar si ya se ha hecho un intento de refresh
    let triedRefreshing = false; // Flag para asegurar que solo intentes refrescar una vez

    const makeRequest = async (endpoint, method, rawBody, formData) => {
        try {
            const url = `${baseURL}${endpoint}`;
            const token = localStorage.getItem('accessToken');

            let headers = {
                'Content-Type': 'application/json',
            };

            if (token) {
                headers['Authorization'] = `Bearer ${token}`;
            }

            let body = '';

            if (rawBody && !formData) {
                body = JSON.stringify(rawBody);
            } else {
                body = rawBody;
            }

            const response = await fetch(url, method === 'GET' ? {
                method,
                headers,
            } : formData ? {
                method,
                body,
            } : {
                method,
                headers,
                body,
            });

            return response;
        } catch (error) {
            console.error(error);
            return { success: false, error: 'Error al conectarse con el servidor. Inténtalo nuevamente más tarde.' };
        }
    };

    const response = await makeRequest(endpoint, method, rawBody, formData);
    // logResponse(response, endpoint);

    if (response.ok) {
        const data = await response.json();
        return { success: true, data };
    } else if ((response.status === 461 || response.status === 462 || response.status === 463) && !triedRefreshing) {
        // Intentar refrescar el token solo si no se ha hecho antes
        triedRefreshing = true;
        const refreshTokenResult = await refreshLogin();
        if (refreshTokenResult && refreshTokenResult.accessToken) {
            // Reintentar la solicitud original después de refrescar el token
            const retryResponse = await makeRequest(endpoint, method, rawBody, formData);
            logResponse(retryResponse, endpoint);
            if (retryResponse.ok) {
                const retryData = await retryResponse.json();
                return { success: true, data: retryData };
            } else if (retryResponse.status === 461 || retryResponse.status === 462 || retryResponse.status === 463) {
                // Si vuelve a fallar después del refresh token, hacer logout
                if (logout) logout();
                alert('Se ha cerrado la sesión por inactividad.');
                window.location.href = '/login';
                return { success: false, data: null };
            }
        } else {
            // Si el refresh también falla, hacer logout directamente
            if (logout) logout();
            alert('Se ha cerrado la sesión por inactividad.');
            window.location.href = '/login';
            return { success: false, data: null };
        }
    } else {
        return { success: false, error: response.error || 'Error al hacer fetch' };
    }
};

const doLogin = async (email, password, logout) => {
    const endpoint = '/auth/login';

    try {
        const result = await doFetch(endpoint, 'POST', {email, password}, false, logout);

        if(result.success) {
            // Guardar el token y el refresh token en localStorage
            localStorage.setItem('accessToken', result.data.accessToken);
            localStorage.setItem('refreshToken', result.data.refreshToken);
            localStorage.setItem('user', JSON.stringify(result.data));
            
            return result.data;
        } else {
            localStorage.removeItem('accessToken');
            localStorage.removeItem('refreshToken');
            localStorage.removeItem('user');
        }

        return result;

    } catch (error) {
        console.error(error);
    }
}

const validateToken = async (logout) => {
    const endpoint = '/auth/validate';

    try {
        const result = await doFetch(endpoint, 'GET', null, false, logout);

        if(result.success) {
            return true;
        }

        return false;
    } catch (error) {
        console.error(error);
    }
}

const refreshLogin = async () => {
    const endpoint = '/auth/token';
    const refreshToken = localStorage.getItem('refreshToken');

    if (!refreshToken) {
        return null; // Si no hay refreshToken, no intentar el refresh
    }

    try {
        const response = await fetch(`https://api.myguestlists.com${endpoint}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ refreshToken }),
        });

        if (response.ok) {
            const result = await response.json();
            // Guardar el token y el refresh token en localStorage
            localStorage.setItem('accessToken', result.accessToken);
            localStorage.setItem('refreshToken', result.refreshToken);
            localStorage.setItem('user', JSON.stringify(result));
            return result;
        } else {
            localStorage.removeItem('accessToken');
            localStorage.removeItem('refreshToken');
            localStorage.removeItem('user');
        }
        return null;
    } catch (error) {
        console.error(error);
        return null;
    }
};

const getMyUsers = async (logout) => {
    const endpoint = '/users/my-users';

    try {
        const result = await doFetch(endpoint, 'GET', null, false, logout);

        if(result.success) {
            return result.data;
        }

        return result;
    } catch (error) {
        console.error(error);
    }
}

const createUser = async (formData, logout) => {
    const endpoint = '/users/publicUser';

    try {
        const result = await doFetch(endpoint, 'POST', formData, false, logout);

        if(result.success) {
            return result.data;
        }

        return result;
    } catch (error) {
        console.error(error);
    }
}

const updateUser = async (userId, formData, logout) => {
    const endpoint = `/users/${userId}`;

    try {
        const result = await doFetch(endpoint, 'PUT', formData, false, logout);

        if(result.success) {
            return result.data;
        }

        return result;
    } catch (error) {
        console.error(error);
    }
}

const deleteUser = async (userId, logout) => {
    const endpoint = `/users/${userId}`;

    try {
        const result = await doFetch(endpoint, 'DELETE', null, false, logout);

        if(result.success) {
            return result.data;
        }

        return result;
    } catch (error) {
        console.error(error);
    }
}

const createCustomUser = async (formData, logout) => {
    const endpoint = '/users';

    try {
        const result = await doFetch(endpoint, 'POST', formData, false, logout);

        if(result.success) {
            return result.data;
        }

        return result;
    } catch (error) {
        console.error(error);
    }
}

const createEvent = async (formData, logout) => {
    const endpoint = '/events';

    try {
        const result = await doFetch(endpoint, 'POST', formData, false, logout);

        if(result.success) {
            return result.data;
        }

        return result;
    } catch (error) {
        console.error(error);
    }
}

const updateEvent = async (eventId, formData, logout) => {
    const endpoint = `/events/${eventId}`;

    try {
        const result = await doFetch(endpoint, 'PUT', formData, false, logout);

        if(result.success) {
            return result.data;
        }

        return result;
    } catch (error) {
        console.error(error);
    }
}

const updateEventAlbum = async (eventId, formData, logout) => {
    const endpoint = `/events/albums/${eventId}`;

    try {
        const result = await doFetch(endpoint, 'PUT', formData, false, logout);

        if(result.success) {
            return result.data;
        }

        return result;
    } catch (error) {
        console.error(error);
    }
}

const deleteEvent = async (eventId, logout) => {
    const endpoint = `/events/${eventId}`;

    try {
        const result = await doFetch(endpoint, 'DELETE', null, false, logout);

        if(result.success) {
            return result.data;
        }

        return result;
    } catch (error) {
        console.error(error);
    }
}

const createVenue = async (formData, logout) => {
    const endpoint = `/venues`;

    try {
        const result = await doFetch(endpoint, 'POST', formData, false, logout);

        if(result.success) {
            return result.data;
        }

        return result;
    } catch (error) {
        console.error(error);
    }
}

const updateVenue = async (venueId, formData, logout) => {
    const endpoint = `/venues/${venueId}`;

    try {
        const result = await doFetch(endpoint, 'PUT', formData, false, logout);

        if(result.success) {
            return result.data;
        }

        return result;
    } catch (error) {
        console.error(error);
    }
}

const getBrand = async (urlSlug, logout) => {
    const endpoint = `/getBrand/${urlSlug}`;

    try {
        const result = await doFetch(endpoint, 'GET', null, false, logout);
        
        if(result.success) {
            return result.data;
        }
    } catch (error) {
        console.error(error);
    }
}


const getPublicEvents = async (filter, filterId, when='upcoming', logout) => {
    const endpoint = `/events/${when}/${filter}/${filterId}`;

    try {
        const result = await doFetch(endpoint, 'GET', null, false, logout);

        if(result.success) {
            return result.data;
        }
    } catch (error) {
        console.error(error);
    }
}

const getPublicAlbums = async (filter, filterId, logout) => {
    const endpoint = `/events/albums/${filter}/${filterId}`;

    try {
        const result = await doFetch(endpoint, 'GET', null, false, logout);

        if(result.success) {
            return result.data;
        }
    } catch (error) {
        console.error(error);
    }
}

const getPublicEvent = async (eventId, logout) => {
    const endpoint = `/events/public/${eventId}`;

    try {
        const result = await doFetch(endpoint, 'GET', null, false, logout);

        if(result.success) {
            return result.data;
        }
    } catch (error) {
        console.error(error);
    }
}

const createTicket = async (body, logout) => {
    const endpoint = '/tickets';

    try {
        const result = await doFetch(endpoint, 'POST', body, false, logout);

        if(result.success) {
            return result.data;
        }

        return result;
    } catch (error) {
        console.error(error);
    }
}

const createCustomTicket = async (body, logout) => {
    const endpoint = '/tickets/nouser';

    try {
        const result = await doFetch(endpoint, 'POST', body, false, logout);

        if(result.success) {
            return result.data;
        }

        return result;
    } catch (error) {
        console.error(error);
    }
}

const getTicketByUserEvent = async (eventId, userId, logout) => {
    const endpoint = `/tickets/event/${eventId}/user/${userId}`;

    try {
        const result = await doFetch(endpoint, 'GET', null, false, logout);

        if(result.success) {
            return result.data;
        }

        return result;
    } catch (error) {
        console.error(error);
    }
}

const getTicketsByEvent = async (eventId, logout) => {
    const endpoint = `/tickets/event/${eventId}`;

    try {
        const result = await doFetch(endpoint, 'GET', null, false, logout);

        if(result.success) {
            return result.data;
        }

        return result;
    } catch (error) {
        console.error(error);
    }
}

const setFreeTicket = async (ticketId, logout) => {
    const endpoint = `/tickets/${ticketId}/free`;

    try {
        const result = await doFetch(endpoint, 'PUT', null, false, logout);

        if(result.success) {
            return result.data;
        }

        return result;
    } catch (error) {
        console.error(error);
    }
}

const checkinTicket = async (ticketId, logout) => {
    const endpoint = `/tickets/${ticketId}/validate`;

    try {
        const result = await doFetch(endpoint, 'PUT', null, false, logout);

        if(result.success) {
            return result.data;
        }

        return result;
    } catch (error) {
        console.error(error);
    }
}

const deleteTicket = async (ticketId, logout) => {
    const endpoint = `/tickets/${ticketId}`;

    try {
        const result = await doFetch(endpoint, 'DELETE', null, false, logout);

        if(result.success) {
            return result.data;
        }

        return result;
    } catch (error) {
        console.error(error);
    }
}

const logOut = async (logout) => {
    const endpoint = '/auth/logout';

    try {
        const result = await doFetch(endpoint, 'POST', null, false, logout);

        if(result.success) {
            localStorage.clear();
            sessionStorage.clear();
            return result.data;
        }
    } catch (error) {
        console.error(error);
    }
}

const getAccessibleEvents = async (logout) => {
    const endpoint = '/events/accessible';

    try {
        const result = await doFetch(endpoint, 'GET', null, false, logout);

        if(result.success) {
            return result.data;
        }
    } catch (error) {
        console.error(error);
    }
}

const getAccessibleVenues = async (logout) => {
    const endpoint = '/venues/accessible';

    try {
        const result = await doFetch(endpoint, 'GET', null, false, logout);

        if(result.success) {
            return result.data;
        }
    } catch (error) {
        console.error(error);
    }
}

const getAccessiblePromoters = async (logout) => {
    const endpoint = '/promoters/accessible';

    try {
        const result = await doFetch(endpoint, 'GET', null, false, logout);

        if(result.success) {
            return result.data;
        }
    } catch (error) {
        console.error(error);
    }
}

const getAccessibleRRPP = async (logout) => {
    const endpoint = '/users/accessible';

    try {
        const result = await doFetch(endpoint, 'GET', null, false, logout);
        if(result.success) {
            return result.data;
        }
    } catch (error) {
        console.error(error);
    }
}

const uploadFile = async (formData, logout) => {
    const endpoint = '/upload';

    try {
        const result = await doFetch(endpoint, 'POST', formData, true, logout);
        if(result.success) {
            return result.data;
        }
    } catch (error) {
        console.error(error);
    }
}

const uploadImageFiles = async (formData, logout) => {
    const endpoint = '/upload/album';

    try {
        const result = await doFetch(endpoint, 'POST', formData, true, logout);
        if(result.success) {
            return result.data;
        }
    } catch (error) {
        console.error(error);
    }
}

const requestPasswordReset = async (body, logout) => {
    const endpoint = '/auth/request-password-reset';

    try {
        const result = await doFetch(endpoint, 'POST', body, false, logout);
        return result;
    } catch (error) {
        console.error(error);
    }
}

const resetPassword = async (body, logout) => {
    const endpoint = '/auth/reset-password';

    try {
        const result = await doFetch(endpoint, 'POST', body, false, logout);
        return result;
    } catch (error) {
        console.error(error);
    }
}

const verifyEmail = async (token, logout) => {
    const endpoint = `/auth/verify-email/${token}`;
    try {
        const result = await doFetch(endpoint, 'GET', null, false, logout);
        if(result?.data?.accessToken) {
            localStorage.setItem('accessToken', result.data.accessToken);
            localStorage.setItem('refreshToken', result.data.refreshToken);
            const user = {
                accessToken: result.data.accessToken,
                refreshToken: result.data.refreshToken,
                role: result.data.role,
                id: result.data.id,
                refId: result.data.refId}
            localStorage.setItem('user', JSON.stringify(user));
            return result.data;
        }
        return result;
    } catch (error) {
        console.error(error);
    }
}

const getMyTickets = async (logout) => {
    const endpoint = '/tickets/my-tickets';

    try {
        const result = await doFetch(endpoint, 'GET', null, false, logout);
        if(result.success) {
            return result.data;
        }
        return result;
    } catch (error) {
        console.error(error);
    }
}

const getRefPhone = async (refId, logout) => {
    const endpoint = `/users/user-phone/${refId}`;

    try {
        const result = await doFetch(endpoint, 'GET', null, false, logout);
        if(result.success) {
            return result.data;
        }
        return result;
    } catch (error) {
        console.error(error);
    }
}

const getTicket = async (ticketId, logout) => {
    const endpoint = `/tickets/${ticketId}`;

    try {
        const result = await doFetch(endpoint, 'GET', null, false, logout);
        if(result.success) {
            return result.data;
        }
        return result;
    } catch (error) {
        console.error(error);
    }
}

const trackMetaEvent = async (eventData, logout) => {
    const endpoint = '/track/meta';

    try {
        const result = await doFetch(endpoint, 'POST', eventData, false, logout);
        if(result.success) {
            return result.data;
        }
        return result;
    } catch (error) {
        console.error(error);
    }
}


const apiHandler = {
    doLogin,
    getBrand,
    createVenue,
    updateVenue,
    getPublicEvents,
    getPublicEvent,
    getPublicAlbums,
    getMyUsers,
    createUser,
    updateUser,
    deleteUser,
    createCustomUser,
    createEvent,
    updateEvent,
    updateEventAlbum,
    deleteEvent,
    createTicket,
    createCustomTicket,
    getTicketByUserEvent,
    getTicketsByEvent,
    checkinTicket,
    deleteTicket,
    refreshLogin,
    validateToken,
    logOut,
    getAccessibleEvents,
    getAccessibleVenues,
    getAccessiblePromoters,
    getAccessibleRRPP,
    setFreeTicket,
    uploadFile,
    uploadImageFiles,
    requestPasswordReset,
    resetPassword,
    verifyEmail,
    getMyTickets,
    getRefPhone,
    getTicket,
    trackMetaEvent
}

export default apiHandler;