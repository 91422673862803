import React, { useEffect, useState, useContext } from 'react';
import {
  Box,
  Text,
  Tag,
  Input,
  FormControl,
  VStack,
  HStack,
  Wrap,
  InputGroup, 
  InputRightElement, 
  Button
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import apiHandler from '../Handlers/apiHandler';
import { appState, userState, userToEditState } from '../Recoil/atoms'; // Importa el estado `userToEditState`
import { CloseIcon } from '@chakra-ui/icons';

import { AuthContext } from '../context/AuthContext';

const roleLabels = {
  masteruser: 'Master User',
  admin: 'Administrador',
  rrpp: 'RRPP',
  comm: 'Promotor',
  user: 'Cliente',
};

const MyUsers = () => {

  const { logout } = useContext(AuthContext);
  
  const [isLoading, setLoading] = useRecoilState(appState);
  const [currentUser] = useRecoilState(userState);
  const [myUsers, setMyUsers] = useState([]);
  const [searchString, setSearchString] = useState('');
  const [userToEdit, setUserToEdit] = useRecoilState(userToEditState); // Estado para almacenar el usuario seleccionado
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    fetchMyUsers();
    setLoading(false);
  }, []);

  const fetchMyUsers = async () => {
    try {
      setLoading(true);
      const users = await apiHandler.getMyUsers(logout);
      if(!users.error) {
        setMyUsers(users);
      }
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const normalizeText = (text) => {
    return text.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
  };

  const handleSearch = (event) => {
    const { value } = event.target;
    setSearchString(normalizeText(value));
  };

  const handleUserClick = (user) => {
    setUserToEdit(user); // Almacena el usuario seleccionado en el estado de Recoil
    navigate('/edit-user'); // Redirige a la página de edición
  };

  const filteredUsers = myUsers?.filter((user) => {
    const fullName = normalizeText(`${user.name} ${user.surnames}`);
    const promoters = normalizeText(user.promoters.map(promoter => promoter.name).join(' '));
    const venues = normalizeText(user.venues.map(venue => venue.name).join(' '));
    const rrpp = user.refId && myUsers.find(u => u.id === user.refId)
      ? normalizeText(`${myUsers.find(u => u.id === user.refId)?.name} ${myUsers.find(u => u.id === user.refId)?.surnames}`)
      : '';
    const roleLabel = normalizeText(roleLabels[user.role] || '');

    const searchTerms = searchString.split(' ')?.filter(term => term);

    return searchTerms.every(term =>
      fullName.includes(term) ||
      promoters.includes(term) ||
      venues.includes(term) ||
      rrpp.includes(term) ||
      roleLabel.includes(term)
    );
  });

  return (
    <>
      {
        (currentUser && myUsers) && <Box p="10px">
          <FormControl id="search" width="100%" mb="20px">
            <InputGroup>
              <Input
                type="text"
                name="search"
                value={searchString}
                onChange={handleSearch}
                placeholder="Buscar por nombre, rol, promotor, venue, rrpp..."
                borderRadius="full"
              />
              {searchString && (
                <InputRightElement>
                  <Button
                    size="sm"
                    onClick={() => setSearchString('')}  // Limpia el campo de búsqueda
                    borderRadius="full"
                    variant="ghost"
                    colorScheme='whiteScheme'
                    aria-label="Clear search"
                  >
                    <CloseIcon />
                  </Button>
                </InputRightElement>
              )}
            </InputGroup>
          </FormControl>

          <VStack spacing={4} align="stretch">
            {filteredUsers.length > 0 ? (
              filteredUsers?.map((user) => (
                <Box
                  key={user.id}
                  w="100%"
                  borderWidth="1px"
                  borderRadius="lg"
                  p={4}
                  mb={2}
                  cursor="pointer"
                  onClick={() => handleUserClick(user)} // Maneja el clic en el usuario
                >
                  <Text fontSize="md" fontWeight="600">{`${user.name} ${user.surnames}`}</Text>
                  <Wrap mt={2} spacing={2}>
                    <Tag fontSize="sm" colorScheme="blue">{roleLabels[user.role]}</Tag>
                    {user.promoters.length > 0 && user.promoters.map((promoter) => (
                      <Tag key={promoter.id} fontSize="sm" colorScheme="green">
                        {promoter.name}
                      </Tag>
                    ))}
                    {user.venues.length > 0 && user.venues.map((venue) => (
                      <Tag key={venue.id} fontSize="sm" colorScheme="orange">
                        {venue.name}
                      </Tag>
                    ))}
                    {user.refId && myUsers.find(u => u.id === user.refId) && (
                      <Tag fontSize="sm" colorScheme="purple">
                        {myUsers.find(u => u.id === user.refId)?.name} {myUsers.find(u => u.id === user.refId)?.surnames}
                      </Tag>
                    )}
                  </Wrap>
                </Box>
              ))
            ) : (
              <Text>No tienes usuarios asignados.</Text>
            )}
          </VStack>
        </Box>
      }
    </>
  );
};

export default MyUsers;