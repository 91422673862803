import React, { useEffect, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';

import apiHandler from '../Handlers/apiHandler';
import dateHandler from '../Handlers/dateHandler';

import { brandState, appState, albumsState, albumState, ticketState, userState, ticketsState, refState, albumToEditState } from '../Recoil/atoms';

import { Box, Image, Modal, ModalBody, ModalHeader, ModalContent, ModalCloseButton, Card, CardHeader, CardBody, CardFooter, Stack, Button, IconButton, useTheme, Tag, TagLeftIcon, Text, useDisclosure, Flex, Tooltip } from '@chakra-ui/react';
import { AddIcon, ArrowForwardIcon, ArrowBackIcon, EditIcon, CopyIcon } from '@chakra-ui/icons';
import { MdLocationOn, MdOutlineQueryStats } from "react-icons/md";
import { LuScanLine } from "react-icons/lu";
import { PiTShirt } from "react-icons/pi";

import utilHandlers from '../Handlers/utilHandlers';

import { useMetaPixel } from '../Pixels/useMetaPixel';
import GalleryComponent from '../Components/GalleryComponent';

import LoginPage from './LoginPage';

import { AuthContext } from '../context/AuthContext';

const Event = () => {

    const { logout } = useContext(AuthContext);
    
    const { albumId } = useParams();

    const [brandData, setBrandData] = useRecoilState(brandState);
    const [album, setEvent] = useRecoilState(albumState);
    const [isLoading, setLoading] = useRecoilState(appState);
    const [user, setUser] = useRecoilState(userState);
    const [ref, setRef] = useRecoilState(refState);
    const [currentUser, setCurrentUser] = useRecoilState(userState);

    const trackEvent = useMetaPixel(brandData?.result?.fb_pixel_id);

    useEffect(() => {
        trackEvent('ViewContent', {
            email: currentUser?.email,
            phone: currentUser?.phone,
            first_name: currentUser?.name,
            last_name: currentUser?.surnames,
            url: window.location.href,
            refId: ref,
            client_user_agent: navigator.userAgent,
            album_name: album?.name,
            album_venue: album?.venue?.name
        });

        trackEvent('ViewAlbum', {
            email: currentUser?.email,
            phone: currentUser?.phone,
            first_name: currentUser?.name,
            last_name: currentUser?.surnames,
            url: window.location.href,
            refId: ref,
            client_user_agent: navigator.userAgent,
            album_name: album?.name,
            album_venue: album?.venue?.name
        }, true);
    }, [trackEvent, currentUser, ref]);

    const navigate = useNavigate();

    const fetchCurrentAlbum = async (albumId) => {
        try {
            setLoading(true);
            const album = await apiHandler.getPublicEvent(albumId, logout);
            await setEvent(album);
            setLoading(false);
        } catch (error) {
            console.error(error);
            setLoading(false);
        }
        
    }

    useEffect(() => {
        setEvent(null);
        fetchCurrentAlbum(albumId);
    }, []);

    const getFormattedDate = (album) => {
        const weekDay = dateHandler.toEsDate(album.start_date_time, 'weekDay');
        const day = dateHandler.toEsDate(album.start_date_time, 'day');
        const month = dateHandler.toEsDate(album.start_date_time, 'month');
        const year = dateHandler.toEsDate(album.start_date_time, 'year');

        const FormattedDate = () => {
            return (
                <span>
                    {weekDay} <strong>{day}</strong> {month}. {year}
                </span>
            );
        }

        return FormattedDate();
    }

    const albumFlyer = album?.flyer;

    return (
        <>
            {   user ? (
                    album ? <>
                        <Box padding="10px"  w="100%" display="flex" flexDir="column" gap="20px" overflow="hidden" borderColor="whiteScheme.300" borderWidth="2px" borderRadius="20px" bgColor="whiteScheme.100" key={album.id}>
                            <Box display="flex" justifyContent="space-between">
                                <Box display="flex" gap="10px">
                                    <IconButton size="md" icon={<ArrowBackIcon />} colorScheme="blackScheme" variant='outline' onClick={() => navigate('/albums')} />
                                    <Box display="flex" flexDirection="column" gap="0px" alignItems="start">
                                        <Text>{album?.name}</Text>
                                        <Text fontSize="xs" color="blackScheme.200">{getFormattedDate(album)}</Text>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                        <GalleryComponent images={album.images} album={album}/>
                    </> : <></>
             ) : <>
                    <LoginPage />
                </>
            }
        </>
    );
}

export default Event;